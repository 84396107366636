"use client";

import isEmpty from "lodash/isEmpty";
import { CommentForm, CommentsList } from "@litonarefin/components/Comments";
import { useEffect, useState } from "react";
import axios from "axios";
import { convertFlatToNestedArray } from "@litonarefin/utils/flatToNestedArray";

function CommnetsArea({ data, post_id, commentStatus }) {
    const [allComments, setAllComments] = useState([]);
    const [totalComments, setTotalComments] = useState(null);
    const [reply, setReply] = useState(null);

    const fetchComments = async (comments) => {
        let {
            data: { data: commentsFetch },
        } = await axios.get(`/api/comments`, {
            params: { post: post_id, status: "all", per_page: 10 },
        });

        let filteredComments = commentsFetch.filter((item) => {
            const givenDate = new Date(item.date);
            const currentDate = new Date();
            const maxGapMinutes = 3;
            const timeDiffMinutes = Math.abs(currentDate.getTime() - givenDate.getTime()) / (1000 * 60);

            return item.status == "approved" || (item.status == "hold" && item.author_name == comments?.author_name && timeDiffMinutes <= maxGapMinutes);
        });
        const nestedArray = convertFlatToNestedArray(filteredComments);
        setTotalComments(nestedArray);
    };

    useEffect(() => {
        fetchComments(allComments);
    }, [allComments]);

    useEffect(() => {
        setAllComments(data);
    }, [data]);

    return (
        <>
            {!isEmpty(allComments) || reply == null ? (
                <div className="jlt-mt-6 sm:jlt-mt-8 md:jlt-mt-12 lg:jlt-mt-16 jlt-bg-white jlt-py-12 jlt-px-8 jlt-rounded-lg shadow-custom">
                    {!isEmpty(allComments) ? (
                        <CommentsList allComments={allComments} totalComments={totalComments} reply={reply} setReply={setReply} post_id={post_id} fetchComments={fetchComments} />
                    ) : null}

                    {reply == null && commentStatus == "open" && <CommentForm reply={reply} setReply={setReply} post_id={post_id} comment_id={0} fetchComments={fetchComments} />}
                </div>
            ) : null}
        </>
    );
}

export default CommnetsArea;
