"use client";

import React from "react";
import { useRouter } from "next/navigation";
import { lowerCaseWithOutSpace } from "@litonarefin/utils/lowerCaseWithOutSpace";
import { startCase } from "lodash";

function AuthorBtn({ author }) {
    const router = useRouter();

    return (
        <h4
            onClick={() => {
                router.push(
                    `/author/${lowerCaseWithOutSpace(author?.node?.name)}`,
                    {
                        scroll: false,
                    }
                );
            }}
            className="jlt-text-base jlt-text-[--primary] jlt-cursor-pointer">
            {startCase(author?.node?.name.toLowerCase() || "Unknown")}
        </h4>
    );
}

export default AuthorBtn;
