"use client";

import { getIcon } from "@litonarefin/utils/icons";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

function SocialShare({ data }) {
    const [copiedText, setCopiedText] = useState({ copied: false });

    const pathname = usePathname();

    const socialPath = `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}${pathname}`;

    useEffect(() => {
        let interval;
        if (copiedText?.copied === true) {
            interval = setInterval(() => {
                setCopiedText({ copied: false });
            }, 6000);
        }
        return () => clearInterval(interval);
    }, [copiedText]);

    return (
        <div>
            <h6 className="jlt-text-[#475467] jlt-leading-6">Share Now:</h6>
            <div className="jlt-mt-4 jlt-flex jlt-items-center jlt-gap-3">
                <CopyToClipboard
                    text={`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}${pathname}`}
                    onCopy={() => setCopiedText({ copied: true })}>
                    <button
                        className={`jlt-border jlt-border-[#D0D5DD] jlt-py-2 jlt-px-4 jlt-rounded-[4px] jlt-text-xs jlt-flex jlt-items-center jlt-gap-2${
                            copiedText?.copied
                                ? " jlt-text-[#039855] jlt-font-bold"
                                : " jlt-text-[#344054]"
                        }`}>
                        {copiedText?.copied ? (
                            <>
                                {getIcon("check")}
                                <span>Copied</span>
                            </>
                        ) : (
                            <>
                                {getIcon("copy")}
                                <span>Copy Link</span>
                            </>
                        )}
                    </button>
                </CopyToClipboard>

                <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer.php?u=${socialPath}&title=${data?.title}`}
                    className="jlt-border jlt-border-[#D0D5DD] jlt-p-2 jlt-rounded">
                    {getIcon("facebook")}
                </a>
                <a
                    target="_blank"
                    href={`http://twitter.com/share?text=${data?.title}&url=${socialPath}`}
                    className="jlt-border jlt-border-[#D0D5DD] jlt-p-2 jlt-rounded">
                    {getIcon("twitter")}
                </a>
                <a
                    target="_blank"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${socialPath}`}
                    className="jlt-border jlt-border-[#D0D5DD] jlt-p-2 jlt-rounded">
                    {getIcon("linkedin")}
                </a>
            </div>
        </div>
    );
}

export default SocialShare;
