"use client";

import { getIcon } from "@litonarefin/utils/icons";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

const SocialShareLeft = ({ data }) => {
    const [copiedText, setCopiedText] = useState({ copied: false });
    const [showSocialShare, setShowSocialShare] = useState(false);

    const pathname = usePathname();

    const socialPath = `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}${pathname}`;

    useEffect(() => {
        let interval;
        if (copiedText?.copied === true) {
            interval = setInterval(() => {
                setCopiedText({ copied: false });
            }, 6000);
        }
        return () => clearInterval(interval);
    }, [copiedText]);

    // Scroll Parcentage
    const scrollParcentage = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 150;

        if (scrolled) {
            setShowSocialShare(true);
        } else {
            setShowSocialShare(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", scrollParcentage);

        return () => window.removeEventListener("scroll", scrollParcentage);
    }, []);

    if (!showSocialShare) return null;

    return (
        <div className="jlt-ml-4">
            <div className="jlt-mt-4 jlt-flex jlt-flex-col jlt-items-center jlt-gap-3">
                <a target="_blank" href={`https://www.facebook.com/sharer.php?u=${socialPath}&title=${data?.title}`} className="jlt-p-4 jlt-rounded-full jlt-bg-white shadow-custom">
                    {getIcon("facebook")}
                </a>
                <a target="_blank" href={`http://twitter.com/share?text=${data?.title}&url=${socialPath}`} className="jlt-p-4 jlt-rounded-full jlt-bg-white shadow-custom">
                    {getIcon("twitter")}
                </a>
                <a target="_blank" href={`https://www.linkedin.com/sharing/share-offsite/?url=${socialPath}`} className="jlt-p-4 jlt-rounded-full jlt-bg-white shadow-custom">
                    {getIcon("linkedin")}
                </a>

                <CopyToClipboard text={`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}${pathname}`} onCopy={() => setCopiedText({ copied: true })}>
                    <button className={`jlt-p-4 jlt-rounded-full jlt-bg-white shadow-custom${copiedText?.copied ? " jlt-text-[#039855] jlt-font-bold" : " jlt-text-[#344054]"}`}>
                        {copiedText?.copied ? <>{getIcon("check")}</> : <>{getIcon("copy")}</>}
                    </button>
                </CopyToClipboard>
            </div>
        </div>
    );
};

export default SocialShareLeft;
